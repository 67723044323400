import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"
import Blockquote from "@components/blockquote"

import ActiveItemGallery from "@components/ActiveItemGallery"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "T-Style Fabric Top Custom Guitar",
    images: ["/images/guitar005-front.jpg"],
  },
  {
    key: 2,
    title: "Back Woodgrain",
    images: ["/images/guitar005-back-main.jpg"],
  },
  {
    key: 3,
    title: "Headstock Front",
    images: ["/images/guitar005-headstock.jpg"],
  },
  {
    key: 4,
    title: "Signed Headstock",
    images: ["/images/guitar005-signed-headstock.jpg"],
  },
  {
    key: 5,
    title: "Neck Back",
    images: ["/images/guitar005-neck-back.jpg"],
  },
  {
    key: 6,
    title: "Randy and Guitar",
    images: ["/images/guitar005-randy-full.jpg"],
  },  
  {
    key: 7,
    title: "Side",
    images: ["/images/guitar005-randy-side.jpg"],
  },
  {
    key: 8,
    title: "Fabric Top",
    images: ["/images/guitar005-randy-top.jpg"],
  },
  {
    key: 9,
    title: "Setup",
    images: ["/images/guitar005-setup.jpg"],
  },
  {
    key: 10,
    title: "Urethane Coat",
    images: ["/images/guitar005-unfinished.jpg"],
  },
  {
    key: 11,
    title: "Back",
    images: ["/images/guitar005-back.jpg"],
  },
  {
    key: 12,
    title: "Glued Fabric Top",
    images: ["/images/guitar005-glued-fabric-top.jpg"],
  },
  {
    key: 13,
    title: "Travis Lee's Test Drive",
    images: ["/images/guitar005-travis-lee.jpg"],
  },
]

const Guitar005 = () => {
  return (
    <PageLayout>
      <SEO
        title="T-Style Fabric Top Custom Guitar | P90 Pickups"
        ogDescription="This T-style fabric top custom guitar features a multilaminate neck and fabulous sounding Tonerider Hot 90 pickups. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>T-Style Fabric Top Custom Guitar</h1>
            <p className="detail">
              <b>Guitar:</b> T-Style Prototype #3
            </p>
            <p className="detail">
              <b>Finish:</b> Fabric Top on Chambered Sapele with Mahogony Top and 5-piece neck
            </p>
            <p className="detail">
              <b>Details:</b> T-Style body with dual Tonerider hot 90 pickups
            </p>
            <ScrollToButton
              id="video"
              content="Watch Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
                This guitar started out with the intention of doing a natural finish. Until I saw this crazy coral paisley fabric! I added a multilaminate neck and the rest is history. I posted a few pictures of this custom guitar in progress to my social media sites & Travis Lee had to have it! After putting down a deposit, I walked him through the rest of the build & gave him a few upgrade options. We added the contoured neck joint and a few other custom touches, including a gloss top with matte back and sides, and black hardware. Tonerider Hot 90 pickups were chosen to go along with my standard CTS pots, Switchcraft switches, etc. Travis was able to test the neck a few times until we got the asymmetrical shape just right for his hands. Mr Lee usually plays shredder guitars with thin wide necks & humbuckers, but he is thoroughly enjoying his King Electric Guitars custom build & we are currently discussing his next custom guitar build!
            </p>
            <Blockquote>
              This was my first experience with a custom build guitar and Randy at King Electric Guitars has set the bar high. Custom may be an understatement, this was a hands-on build. From colors, to finish, to electronics, down to the neck profile, my hands were involved. The end result is one of a kind guitar that is specifically for me. I couldn't be happier with this guitar.
            </Blockquote>
            <h2>Specifications</h2>
            <ul>
              <li>5-Piece Vertical Grain Neck (Sapele, Maple, Black Walnut)</li>
              <li>Katalox Fretboard</li>
              <li>25.5-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Mediium Cryowire Frets</li>
              <li>Dual Action Truss Rod</li>
              <li>Bolt on Neck With Inserts & Stainless Machine Screws</li>
              <li>No Fretboard Inlay (Inlay Delete)</li>
              <li>Glowing Side Dots</li>
              <li>Bone nut (Dyed Black)</li>
              <li>Hipshot Open Gear Locking Tuners</li>
              <li>2-Piece Sapele Chambered Body</li>
              <li>Mahogony Top</li>
              <li>Coral Paisley Fabric top Finish</li>
              <li>Contoured, Recessed Neck Joint</li>
              <li>Angled Body Contours (Back)</li>
              <li>Decorative Wood Inlay on Back</li>
              <li>Black Binding</li>
              <li>Urethane Topcoat (Glossy top, Matte Back & Sides)</li>
              <li>Wilkinson Bridge With Compensated Saddles</li>
              <li>Black Hardware</li>
              <li>Tonerider hot 90 Pickups</li>
              <li>CTS 500k Pots</li>
              <li>Switchcraft 3 way Switch</li>
              <li>Switchcraft Input Jack</li>
              <li>.047UF Orange Drop Cap</li>
              <li>Vintage Cloth Wire</li>
            </ul>
            <h2>Price and Availability</h2>
            <p className="red">
              <strong>SOLD</strong>
            </p>
          </div>
        }
      />
      <div className="section textured" id="video">
          <VideoPlayer youtubeId='8cTwMXmwSic' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Want me to build you something like it? Let's talk!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default Guitar005